//QuestionnaireForm & LoginForm
export const TRANSITION_TIMEOUT = 1000;

//Theme
export const DENTAPRIME_PRIMARY_COLOR = "#8d2d46";
export const DENTAPRIME_SECONDARY_COLOR = "#ffffff";
export const MARGIN = "0.75vw";
export const PADDING = "1vw";

//BackendAdapter
export const BACKEND_URL =
  process.env.NODE_ENV === "production"
    ? String(process.env.REACT_APP_EXTERNAL)
    : "http://192.168.2.198:8000"; //Cookies
// export const SESSION_COOKIE_NAME = 'Session';
export const PATIENT_ID_COOKIE = "patientID";
export const SESSION_ROLE_COOKIE = "sessionRole";
export const CORE_ACCESS_TOKEN_COOKIE = "CoreToken";
export const FACILITY_NAME_COOKIE = "facilityName";
export const CITY_LOCATION_NAME = "CityLocation";
export const AGENT = "Agent";
export const BACKEND_VERSION = "1.0";
export const countryList = [
  "BG",
  "DE",
  "CH",
  "AT",
  "US",
  "GB",
  "LU",
  "IL",
  "NL",
  "DK",
  "FI",
  "SE",
  "RO",
  "FR",
  "IE",
  "NO",
  "BE",
  "LI",
  "RU",
  "IT",
];

// const Iliyan_id = '00126000011AOkFAAW'
//patient_id:73168
