import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import ReCAPTCHA from "react-google-recaptcha";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';
import { PATIENT_ID_COOKIE } from '../../Resources/constants';

const RecaptchaPage: React.FC = () => {
    let history = useHistory();

    const handleCaptchaChange = (value: string | null) => {
        if (value) {
            if (localStorage.getItem("local") === "false") {
                if (localStorage.getItem("wfid") === "" && Cookies.get(PATIENT_ID_COOKIE) === "undefined") {
                    history.push("error")
                } else {
                    history.push("filledForm");
                }
            } else {
                history.push("login")
            }
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            minHeight="40vh"
            bgcolor="#f0f2f5"
            p={2}
        >
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                bgcolor="white"
                p={3}
                borderRadius={2}
                boxShadow={3}
                textAlign="center"
                width="80%"
                maxWidth="400px"
                height="auto"
            >
                <Typography variant="h5" component="h1" gutterBottom>
                    Verification/Verifizierung/Верификация
                </Typography>
                <Box mb={2} width="100%" display="flex" justifyContent="center">
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY + ""}
                        onChange={handleCaptchaChange}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default RecaptchaPage;
