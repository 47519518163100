import { CircularProgress, Container, Fade } from "@material-ui/core";
import { Form, Formik, useFormikContext } from "formik";
import Cookies from "js-cookie";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { useHistory } from "react-router-dom";
import { PopupAlert } from "../../Hooks";
import {
    AGENT,
    CITY_LOCATION_NAME,
    FACILITY_NAME_COOKIE,
    PATIENT_ID_COOKIE,
    TRANSITION_TIMEOUT,
} from "../../Resources/constants";
import { Agents, CityClinics, FacilityNames, Languages } from "../../Resources/enums";
import CheckDataList from "./Components/CheckDataList";
import FacilitySelect from "./Components/FacilitySelect";
import Stepper from "./Components/Stepper";
import QuestionGrid from "./Components/QuestionsGrid";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import XRayConsent from "./Components/XRayConsent";
import SignatureBox from "./Components/SignatureBox";
import FormattedText from "../../Components/FormattedText";
import { PageTitle, Question, ShowIf } from "../../Types";
import { DisableLanguageContext } from "../../Contexts";
import ImageAgreement from "./Components/ImageAgreement";
import {
    closeForm, getLanguage, getQuestionsAndAnswers,
} from "../../Providers/backend";
import GridWrapper from "./Components/GridWrapper";
import CustomFormikContextProvider from "../../Contexts/Providers/FormicContextProvider";
import FaceCapture from "../FaceCapture";
import { useSelectLanguage } from "../TopBar/Components/LanguageSelect/Hooks";
import CBCTScan from "./Components/CBCTScan";
import ConsentUK from "./Components/ConsentUK";

const QuestionnaireForm = (): ReactElement => {
    const [pageTitles, setPageTitles] = useState<PageTitle[]>([]);
    const [facilityName, setFacilityName] = useState<FacilityNames | undefined>(
        Cookies.getJSON(FACILITY_NAME_COOKIE)
    );
    const [cityClinic, setCityClinic] = useState<CityClinics | undefined>(
        // Cookies.getJSON(CITY_LOCATION_NAME)
        undefined
    );
    const [agent, setAgent] = useState<Agents | undefined>(
        // Cookies.getJSON(CITY_LOCATION_NAME)
        undefined
    );
    const [language, setLanguage] = useState<Languages>(
        Cookies.getJSON("Language") || Languages.DE
    );
    const languages = Object.values(Languages);

    //hook for language change
    const [selectedLanguage, changeLanguage] = useSelectLanguage(languages);

    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentStep, setCurrentStep] = useState(0);
    const [signatureCan, setSignatureCan] = useState<any>();
    const [stepUpdateAttempt, setStepUpdateAttempt] = useState(0);
    const { i18n } = useTranslation();
    const history = useHistory();
    const { promiseInProgress } = usePromiseTracker();
    const [, setDisableLanguage] = useContext(DisableLanguageContext);
    const patientID = Cookies.getJSON(PATIENT_ID_COOKIE);
    let canvas: any = {};
    const [renderImage, setRenderImage] = useState<boolean>(true);
    const [renderIC, setRenderIC] = useState<boolean>(true);
    const [renderDeclaration, setRenderDeclaration] = useState<boolean>(true);

    const [renderConsent, setRenderConsent] = useState<boolean>(true);
    const [renderCBCT, setRenderCBCT] = useState<boolean>(true);
    useEffect(() => {

    }, [canvas, signatureCan])

    const [local, setLocal] = useState<boolean>();
    const [shown, setShown] = useState<boolean>();
    useEffect(() => {
        setLocal(localStorage.getItem("local") === "true" ? true : false);
        setShown(localStorage.getItem("shown") === "true" ? true : false);
    }, [shown, local]);

    //eslint-disable-next-line max-len
    const [directionDown, setDirectionDown] = useState((Math.ceil(window.pageYOffset) >= (document.body.scrollHeight - window.innerHeight)));
    function handleScrollDown() {
        window.scrollBy({
            top: 200,
            left: 0,
            behavior: "smooth"
        });
    }
    function handleScrollUp() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }
    window.onbeforeunload = function () {
        history.push("/login");
        return "Leaving this page will reset the wizard";
    };
    window.onunload = function () {
        local === true ? history.push("/login") : history.push("/error");
        return "Leaving this page will reset the wizard";
    };
    window.onload = () => {
        setDirectionDown(false);
    };
    window.addEventListener("popstate", () => {
        Object.keys(Cookies.get()).forEach(cookieName => Cookies.remove(cookieName));
        history.goBack();

    });
    window.onscroll = () => {
        //eslint-disable-next-line max-len
        setDirectionDown(!(Math.ceil(window.pageYOffset) >= ((document.body.scrollHeight - window.innerHeight) - 20)));
    };
    const UpdateShowIf = (newQuestions: Question[]): Question[] => {

        const updateAnswerValueInShowIf = (s: ShowIf): ShowIf => {
            s.answerValue = newQuestions
                .find(j => j.questionID === s.question_uid)
                ?.answer
                .answersList
                ?.find(q => q.answerID === s.answer_uid)
                ?.text;

            return s;
        };

        return newQuestions
            .map(i => {
                if (i.showIf) {
                    i.showIf = updateAnswerValueInShowIf(i.showIf);
                }
                if (i.showIfList) {
                    i.showIfList.map(j => updateAnswerValueInShowIf(j));
                }
                return i;
            });
    };
    const determineInitialStep = () => {
        return facilityName ? (cityClinic ? (language ? (local ? 1 : 3) : 1) : local ? 1 : 0) : 0;
    };
    //Sets cookie that disables the language change option after page 2
    useEffect(() => {
        local === true ?
            setDisableLanguage(currentStep > 1 ? true : false) : setDisableLanguage(false);
    }, [currentStep, setDisableLanguage]);
    //if is local page returns to second page if is not local returns on first page
    const localPage = local === false || local === undefined ? 0 : 1;
    useEffect(() => {
        if (!facilityName) {
            setCurrentStep(0);
            return;
        }

        Cookies.set("Language", language + "");
        if ((localStorage.getItem("wfid") !== "") || localStorage.getItem("local") !== "false") {
            if (patientID !== "undefined") {
                trackPromise(
                    getQuestionsAndAnswers()
                        .then((val) => {
                            setQuestions(UpdateShowIf(val.data.questions));
                            setPageTitles(val.data.pageTitles);
                            setCurrentStep(determineInitialStep());
                        })
                        .catch((err) => {
                            console.log(err);
                            setCurrentStep(0);
                            PopupAlert("There is an error while loading your questions.", "error");
                            history.push(local ? "/login" : "/error");
                            history.goForward();
                        })
                );
            } else {
                history.push("error");
            }
        }
    }, [facilityName, cityClinic, patientID, language, local, history]);


    let handleSubmit;
    if (local) {
        handleSubmit = (values: { [s: string]: any }) => {
            // if (canvas.current.isEmpty() && local) {
            //     PopupAlert("The signature must not be empty", "error");
            //     return;
            // }

            const handleFormSubmit = () => {
                setDisableLanguage(false);
                history.push("/congratulationsPage");
                history.goForward();
            };

            closeForm(values, "")
                .then(handleFormSubmit)
                .catch((err) => {
                    console.log(err);
                    PopupAlert("There was an error while updating your answers.", "error");
                });
        };
    } else {
        handleSubmit = (values: { [s: string]: any }) => {

            const handleFormSubmit = () => {
                setDisableLanguage(false);
                history.push("/congratulationsPage");
                history.goForward();
            };

            closeForm(values, "")
                .then(handleFormSubmit)
                .catch((err) => {
                    console.log(err);
                    PopupAlert("There was an error while updating your answers.", "error");
                });
        };
    }
    const steps: { name: string; element: ReactElement, checkValidity: () => boolean, }[] = [];
    // steps.push({
    //     name: "test",
    //     element: (<CameraCapture />),
    //     checkValidity: (): boolean => true
    // })
    //Select language of the form component
    if (local) {
        steps.push(
            {
                name: "Location",
                element: (
                    <FacilitySelect
                        facilityName={facilityName}
                        facilities={Object.values(FacilityNames)}
                        handleOnChange={(ch) => {
                            if (ch === "Dentaprime London") {
                                i18n.changeLanguage("en");
                                setLanguage(Languages.EN);
                            }
                            Cookies.set(FACILITY_NAME_COOKIE, ch);
                            setFacilityName(ch);
                        }}
                    />
                ),
                checkValidity: (): boolean =>
                    Cookies.getJSON(FACILITY_NAME_COOKIE) !== undefined
            }
        );
        if (Cookies.getJSON(FACILITY_NAME_COOKIE) === FacilityNames.CITY)
            steps.push(
                {
                    name: "Location City",
                    element: (
                        <FacilitySelect
                            facilityName={cityClinic}
                            facilities={Object.values(CityClinics)}
                            handleOnChange={(ch) => {
                                Cookies.set(CITY_LOCATION_NAME, ch);
                                setCityClinic(ch);
                            }}
                        />
                    ),
                    checkValidity: (): boolean => true
                }
            );
        if (Cookies.getJSON(FACILITY_NAME_COOKIE) === FacilityNames.CLINIC)
            steps.push(
                {
                    name: "Agent",
                    element: (
                        <FacilitySelect
                            facilityName={agent}
                            facilities={Object.values(Agents)}
                            handleOnChange={(ch) => {
                                console.log(ch);
                                if (ch === "Academy") {
                                    Cookies.set(AGENT, "SCH");
                                } else if (ch === "Vista Park") {
                                    Cookies.set(AGENT, "City - Vista Park");
                                } else {
                                    Cookies.set(AGENT, ch);
                                }

                                setAgent(ch);
                            }}
                        />
                    ),
                    checkValidity: (): boolean => true
                }
            );
    }
    if (Cookies.get("facilityName") !== "Dentaprime London") {
        steps.push(
            {
                name: "Language",
                element: (
                    <FacilitySelect
                        facilityName={language}
                        facilities={languages}
                        handleOnChange={(ch) => {
                            if (facilityName === "Dentaprime City" && ch === Languages.RU) {
                                i18n.changeLanguage("bg");
                                setLanguage(Languages.BG);
                            } else {
                                i18n.changeLanguage(ch.toLowerCase());
                                Cookies.set("Language", ch);
                                setLanguage(ch);
                            }
                        }}
                    />
                ),
                checkValidity: (): boolean => true
            }
        );
    }
    //Takes only the first page of questionnaire
    const removedFirstPage = pageTitles.slice(0, 1).shift();
    steps.push(
        {
            name: String(removedFirstPage?.title),
            element: (
                <QuestionGrid
                    key={removedFirstPage?.title}
                    questions={questions.filter((i) => i.page === 0)}
                />
            ),
            checkValidity: (): boolean => true
        }
    );
    if (renderDeclaration === true && Cookies.get("facilityName") !== "Dentaprime London") {
        steps.push(
            {
                name: "Image Agreement",
                element: <ImageAgreement
                    patient={patientID}
                    step={currentStep}
                    onStepChange={setCurrentStep}
                    isRendered={setRenderDeclaration}

                />,
                checkValidity: (): boolean => true
            }
        );
    }
    if ((local === false) && (renderImage === true)) {
        steps.push(
            {
                name: "Selfie",
                element: <FaceCapture
                    patient={patientID}
                    step={currentStep}
                    onStepChange={setCurrentStep}
                    isRendered={setRenderImage}
                />,
                checkValidity: (): boolean => true
            }
        );
    }
    // Takes all questionnaire pages except first one.
    const newPages = pageTitles.slice(1);
    steps.push(
        ...newPages.map((val: PageTitle) => {
            const filteredQuestions = questions.filter((i) => i.page === val.page);
            return {
                name: val.title,
                element: (
                    <QuestionGrid
                        key={val.title}
                        questions={filteredQuestions}
                    />
                ),
                checkValidity: (): boolean => true
            };
        })
    );
    // add confirmation if is in UK version
    if (Cookies.get("facilityName") === "Dentaprime London") {
        if (renderCBCT) {
            steps.push({
                name: "CBCT scan",
                element: <CBCTScan questions={questions} patient={patientID}
                    step={currentStep}
                    onStepChange={setCurrentStep}
                    isRendered={setRenderCBCT} />,
                checkValidity: (): boolean => true
            })
        }
        if (renderConsent) {
            steps.push({
                name: "INFORMED CONSENT",
                element: <ConsentUK questions={questions} patient={patientID}
                    step={currentStep}
                    onStepChange={setCurrentStep}
                    isRendered={setRenderConsent} />,
                checkValidity: (): boolean => true
            })
        }
    };
    steps.push(
        {
            name: "Check your data",
            element: <CheckDataList pageTitles={pageTitles} questions={questions} />,
            checkValidity: (): boolean => true
        }
    );
    if ((Cookies.get("facilityName") !== "Dentaprime London") && local === true) {
        if ((renderIC === true)) {
            steps.push({
                name: "",
                element: <XRayConsent patientName={questions} patientID={patientID}
                    step={currentStep}
                    onStepChange={setCurrentStep}
                    isRendered={setRenderIC} />,
                checkValidity: (): boolean => true
            });
        }
    }
    if ((local)) {
        steps.push(
            {
                name: "Please sign below",
                element: (
                    <SignatureBox
                        setCanvas={(val) => {
                            canvas = val;
                        }}
                        setSignature={setSignatureCan}
                        handleSignatureWritten={undefined}
                    />
                ),
                checkValidity: (): boolean => true
            }
        );
    }
    if (promiseInProgress) {
        return <CircularProgress />;
    }

    return <CustomFormikContextProvider step={currentStep}
        setStep={setCurrentStep}
        stepUpdateAttempt={stepUpdateAttempt}
        setStepUpdateAttempt={setStepUpdateAttempt}>
        <Formik
            initialValues={Object.assign(
                {},
                ...questions.map((question: Question) => {
                    return {
                        [question.questionID]: {
                            answerValue: question.answer.answerValue,
                            remark: question.answer.answersList?.find(i => i.remark)?.remark || "",
                            required: question.required,
                            page: question.page
                        }
                    };
                })
            )}
            validateOnChange
            onSubmit={handleSubmit}
        >
            {(formikProps: {
                handleSubmit: ((event: React.FormEvent<HTMLFormElement>) => void) | undefined;
            }) => (
                <>
                    <Fade
                        in={true}
                        mountOnEnter={true}
                        appear={true}
                        unmountOnExit={true}
                        exit={false}
                        timeout={TRANSITION_TIMEOUT}
                    >
                        <Form onSubmit={formikProps.handleSubmit}>
                            <Container style={{ marginTop: "6%", marginBottom: "5%" }}>
                                <FormattedText text={steps[currentStep].name} variant="h1" />
                            </Container>
                            <GridWrapper>
                                {steps[currentStep].element}
                            </GridWrapper>
                            <Container
                                style={{
                                    height: "27vh",
                                }}
                            >
                                <Grid direction="row" alignItems="right">
                                    {directionDown ? <IconButton
                                        aria-label="downIcon"
                                        size="large"
                                        style={{
                                            position: "fixed", alignContent: "right",
                                            bottom: "30%", right: "0%", height: "100px",
                                        }}>
                                        <ArrowCircleDownIcon
                                            fontSize="large"
                                            color="error"
                                            sx={{ fontSize: 100 }}
                                            onClick={() => {
                                                handleScrollDown();
                                            }} />
                                    </IconButton> : ((Math.ceil(window.pageYOffset) > 0) ? <IconButton
                                        style={{
                                            position: "fixed", alignContent: "right",
                                            top: "20%", right: "0%",
                                            height: "100px"
                                        }}>
                                        <ArrowCircleUpIcon
                                            fontSize="large"
                                            color="error"
                                            sx={{ fontSize: 100 }}
                                            onClick={() => {
                                                handleScrollUp();
                                            }} />
                                    </IconButton> : null)}
                                </Grid>
                            </Container>
                        </Form>
                    </Fade>
                    <Stepper
                        canvas={signatureCan}
                        pageTitles={pageTitles}
                        questions={questions}
                        stepsLength={steps.length}
                        step={currentStep}
                        stepValidation={steps[currentStep].checkValidity}
                        onStepChange={setCurrentStep}
                        visualization={[renderDeclaration, renderImage, renderIC, renderConsent, renderCBCT]}
                    />
                </>
            )}
        </Formik>
    </CustomFormikContextProvider>;
};

export default QuestionnaireForm;