import React, { ReactElement, useContext } from "react";
import { Button, Grid, Paper } from "@material-ui/core";
import Cookies from "js-cookie";
import FormattedText from "../../../../Components/FormattedText";
import { FACILITY_NAME_COOKIE } from "../../../../Resources/constants";
import SubmitDialog from "./Components/SubmitDialog";
import ProgressBar from "./Components/ProgressBar";
import { ReactComponent as NextArrow } from "./Assets/next_arrow.svg";
import { ReactComponent as BackArrow } from "./Assets/back_arrow.svg";
import { PopupAlert } from "../../../../Hooks";
import { useFormikContext } from "formik";
import { updateAnswers } from "../../../../Providers/backend";
import { IFormikContext, FormikCtx } from "../../../../Contexts/formikContext";
import { PageTitle, Question } from "../../../../Types";

const Stepper = ({
    canvas,
    stepsLength,
    pageTitles,
    questions,
    step,
    stepValidation,
    onStepChange,
    visualization,
}: {
    canvas: any;
    stepsLength: number;
    pageTitles: PageTitle[];
    questions: Question[];
    step: number;
    stepValidation: () => boolean;
    onStepChange: (pageNum: React.SetStateAction<number>) => void;
    visualization: any;
}): ReactElement => {
    const facilityName: string | undefined = Cookies.get("facilityName");
    const isLocalFalse: boolean = localStorage.getItem("local") === "false";
    const determineHideSteps = (): { declarationHide: number; consentHide: number } => {
        let declarationHide = 60, consentHide = 60;

        const adjustForVisualization = (
            visIndex: number,
            decHideValue: number,
            consHideValue: number
        ): void => {
            if (visualization[visIndex]) {
                declarationHide = decHideValue;
                consentHide = consHideValue;
            }
            else {
                declarationHide = decHideValue - 1;
                consentHide = consHideValue - 1;
            }
        };

        if (facilityName?.includes("Dentaprime City")) {
            adjustForVisualization(0, 4, 12);
            adjustForVisualization(1, 4, 12);
        } else if (facilityName?.includes("Dentaprime London")) {
            if (visualization[3] && visualization[4]) {
                declarationHide = 14;
                consentHide = 15;
            } else if (visualization[3] || visualization[4]) {
                declarationHide = 14;
                consentHide = 14;
            }
        } else if (facilityName?.includes("Dentaprime Clinic")) {
            adjustForVisualization(1, isLocalFalse ? 2 : 4, isLocalFalse ? 19 : 22);
            adjustForVisualization(0, isLocalFalse ? 2 : 4, 22);
        }

        if (!visualization[0]) declarationHide = 50;
        if (!visualization[1] || !visualization[2]) consentHide = 50;

        return { declarationHide, consentHide };
    };

    const customFormikContext = useContext<IFormikContext>(FormikCtx);
    const { errors, values } = useFormikContext();
    const { declarationHide, consentHide } = determineHideSteps();

    const filteredValues = React.useMemo(() => {

        if (typeof values === "object" && values) {
            // Logic for "Dentaprime Clinic" based on isLocalFalse
            if (facilityName === "Dentaprime Clinic") {
                return Object.entries(values)
                    .filter(([_, value]) =>
                        value.page === (isLocalFalse ? (((visualization[0] || visualization[1]) && step > 2) ? step - 2 : step - 1) : (visualization[0] && step > 4 ? step - 4 : step - 3))
                    )
                    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
            }

            // Logic for "Dentaprime London"
            if (facilityName === "Dentaprime London") {
                return Object.entries(values)
                    .filter(([_, value]) =>
                        value.page === step - 1
                    )
                    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
            }
        }

        return {};
    }, [values, step, visualization]);


    const handlePageChange = async (pageNum: React.SetStateAction<number>) => {
        if (stepValidation() && Object.keys(errors).length === 0) {
            //TODO: check when is not local
            if (
                Object.keys(filteredValues).length > 0
            ) {
                try {
                    await updateAnswers(filteredValues);
                    onStepChange(pageNum);
                } catch {
                    PopupAlert("Was not able to update answers.", "error");
                }
            } else {
                onStepChange(pageNum);
            }
        } else {
            new Set(Object.values(errors) as unknown as string).forEach((msg) =>
                PopupAlert(msg, "error")
            );
        }
        window.scrollTo(0, 0);
    };

    const buttonStyles = {
        width: "100%",
        height: "7vh",
        borderRadius: "0",
        color: "#fff",
    };

    const buttonTextStyle = { fontSize: 22, fontWeight: 900 };


    const BackButton = () => (
        <Button
            style={{
                ...buttonStyles,
                background: "linear-gradient(0deg, rgb(100,105,107) 0%, rgb(164,171,177) 100%)",
                border: "#fff",
            }}
            disabled={step === 0}
            variant="outlined"
            onClick={() => handlePageChange(step - 1)}
        >
            <BackArrow style={{ marginRight: "3%" }} />
            <FormattedText style={buttonTextStyle} text="Back" />
        </Button>
    );

    const NextButton = () => {
        if (step === stepsLength - 1) {
            return (
                <SubmitDialog
                    canvas={canvas}
                    pageTitles={pageTitles}
                    questions={questions}
                    btnStyle={buttonStyles}
                    btnTextStyle={buttonTextStyle}
                />
            );
        }

        return (
            <Button
                style={buttonStyles}
                disabled={Cookies.getJSON(FACILITY_NAME_COOKIE) === ""}
                onClick={() => {
                    customFormikContext.setStepUpdateAttempt(
                        customFormikContext.stepUpdateAttempt + 1
                    );
                    handlePageChange(step + 1);
                }}
            >
                <FormattedText style={buttonTextStyle} text="Forward" />
                <NextArrow style={{ marginLeft: "3%" }} />
            </Button>
        );
    };

    return (
        <Paper
            elevation={2}
            style={{
                bottom: "0%",
                left: "0%",
                width: "100%",
                boxShadow: "0px -10px rgb(236, 236, 236, 0.4)",
            }}
        >
            <Grid container>
                {Cookies.get("facilityName") !== "Dentaprime London" && (
                    <Grid item xs={12}>
                        <FormattedText text={`${step + 1}/${stepsLength}`} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <ProgressBar currentStep={step} steps={stepsLength} />
                </Grid>
                {(step === consentHide || step === declarationHide) ? (
                    <Grid item xs={12}>
                        <BackButton />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={6}>
                            <BackButton />
                        </Grid>
                        <Grid item xs={6}>
                            <NextButton />
                        </Grid>
                    </>
                )}
            </Grid>
        </Paper>
    );
};

export default Stepper;
